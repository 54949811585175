var debounce = function (fn) {
  var frame;

  return function (...params) {
    if (frame) {
      cancelAnimationFrame(frame);
    }

    frame = requestAnimationFrame(function() {
      fn(...params);
    });
  };
};

var storeScroll = function(element) {
  var sliderElement = element.target.querySelector(':first-child');

  var elementWidth = 0;
  if (sliderElement) {
    elementWidth = sliderElement.getBoundingClientRect().width;
  }

  if (elementWidth !== 0) {
    var sliderBox = element.target.parentElement;
    var scrollableWidth = element.target.scrollWidth - element.target.clientWidth;
    var position = element.target.scrollLeft;

    if (position >= 0 && ((position < elementWidth && scrollableWidth >= elementWidth) || (scrollableWidth <= elementWidth && position < scrollableWidth))) {
      element.target.nextElementSibling.querySelector('.dot').classList.remove('dot-mid', 'dot-end');
      sliderBox.classList.remove('end');
      hidePrevBubble(sliderBox);
      showNextBubble(sliderBox);
    } else if ((position === elementWidth || position > elementWidth) && (position < scrollableWidth )) {
      element.target.nextElementSibling.querySelector('.dot').classList.remove('dot-mid', 'dot-end');
      element.target.nextElementSibling.querySelector('.dot').classList.add('dot-mid');
      sliderBox.classList.remove('end');
      showPrevBubble(sliderBox);
      showNextBubble(sliderBox);
    } else {
      element.target.nextElementSibling.querySelector('.dot').classList.remove('dot-mid', 'dot-end');
      element.target.nextElementSibling.querySelector('.dot').classList.add('dot-end');
      sliderBox.classList.add('end');
      showPrevBubble(sliderBox);
      hideNexBubble(sliderBox);
    }
  }
};

var sliderHasNext = function(slider) {
  var eWidth = 0;
  var childCount = slider.querySelectorAll('.slider__element, .slider-element-with-date').length;
  var sWidth = slider.getBoundingClientRect().width;
  if (slider.hasChildNodes()) {
    eWidth = slider.querySelector('.slide').getBoundingClientRect().width;
    let marginEl = slider.querySelector('.slide:nth-of-type(2)');
    if (marginEl) {
      let margin = window.getComputedStyle(marginEl).margin.split(' ');
      if (margin.length === 4) {
        eWidth += parseInt(margin[1].replace('px', ''));
        eWidth += parseInt(margin[3].replace('px', ''));
      } else if (margin.length === 3) {
        eWidth += 2 * parseInt(margin[1].replace('px', ''));
      } else if (margin.length === 1) {
        eWidth += (2 * parseInt(margin[0].replace('px', '')));
      }
    }
    let paddingEl = slider.querySelector('.slide:nth-of-type(2)');
    if (paddingEl) {
      let padding = window.getComputedStyle(paddingEl).padding.split(' ');
      if (padding.length === 4) {
        eWidth += parseInt(padding[1].replace('px', ''));
        eWidth += parseInt(padding[3].replace('px', ''));
      } else if (padding.length === 3) {
        eWidth += 2 * parseInt(padding[1].replace('px', ''));
      } else if (padding.length === 1) {
        eWidth += (2 * parseInt(padding[0].replace('px', '')));
      }
    }
  }
  var tWidth = childCount * eWidth;

  return tWidth > sWidth;
};

var showPrevBubble = function(slider) {
  slider.classList.add('has-prev');
};

var hidePrevBubble = function(slider) {
  slider.classList.remove('has-prev');
};

var showNextBubble = function(slider) {
  slider.classList.add('has-next');
};

var hideNexBubble = function(slider) {
  slider.classList.remove('has-next');
};

function initSliderBox(element = document) {
  element.querySelectorAll('.slider .slider__elements').forEach(function(slider) {
    if (sliderHasNext(slider) || slider.querySelector('.slide').classList.contains("forceNextButton")) {
      showNextBubble(slider.closest('.slider'));
    }
    slider.addEventListener('scroll', debounce(storeScroll));
  });

  element.querySelectorAll('.slider .content-switch, .slider-box .content-switch').forEach(function(toggle) {
    toggle.addEventListener('click', function() {
      this.closest('.slider-box').classList.toggle('most-read');
      this.closest('.slider-box').classList.toggle('most-commented');
    });
  });

  element.querySelectorAll('.slider .next-bubble, .slider .prev-bubble').forEach(function(btn) {
    btn.addEventListener('click', function() {
      var scrollElement = this.parentElement;
      var scrollWidth = scrollElement.scrollWidth;
      var scrollStepWidth = scrollElement.firstElementChild.getBoundingClientRect().width;
      var scrollPosition = scrollElement.scrollLeft;

      if (scrollPosition < scrollWidth) {
        if (this.classList.contains('next-bubble')) {
          scrollElement.scroll({
            left: scrollElement.scrollLeft + scrollStepWidth,
            top: 0,
            behavior: 'smooth'
          });
        } else {
          scrollElement.scroll({
            left: scrollElement.scrollLeft - scrollStepWidth,
            top: 0,
            behavior: 'smooth'
          });
        }
      }
    });
  });
}

document.addEventListener('DOMContentLoaded', () => initSliderBox());